import { useTranslation } from "react-i18next";

import { Button } from "@/components/ui";
import { useState } from "react";
import { makeRequest } from "../../../services/makeRequest";

import statusElite from "../../../assets/img/status/elite.svg";
import { t } from "i18next";
import useLadyService from "@/services/LadyService";
import { Link } from "react-router-dom";
import showToast from "../../toast/Toast";
import Popup from "../Popup";
import moment from "moment/moment";

const RemoveEliteAdPopup = (props) => {
  const { setOpen, slug: slugProfile, name, id, setElite, elite_to } = props;

  const onClose = () => {
    setOpen(false);
  };

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = () => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const route = `user/elite/remove`;

        const method = "DELETE";
        const payload = {
          profiles: [id],
        };

        await makeRequest({ route, method, payload });
        setElite(false);
        setIsLoading(false);
        onClose();
        showToast({
          message: t("success"),
          variant: "success",
        });
      } catch (error) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };

    fetchData();
  };

  const liOptions = [<li>{t("highad")}</li>, <li>{t("placein")}</li>];

  return (
    <Popup isLoading={isLoading} {...props}>

      <div className="popup-form__inner">
        <h3> {t("deactive")} Elite?</h3>
        <picture className="text-center">
          <source srcSet={statusElite} media="(min-width: 767.98px)"/>
          <img src={statusElite} alt=""/>
        </picture>

        <span>
        {t("stauselite")}: <ul>{liOptions.map((i) => i)}</ul>
      </span>

        <span className="p1 color-main text-center">
        {t("eliteallow")}
          <p className="p1 color-green text-center mt-4">
          +50% {t("toviews")} {t("and")} +20% {t("toorders")}
        </p>
      </span>

        <div className={`attention-box important color-main`}>
          <span className={"attention-box__word"}>{t("important")}! </span>
          {t("eliteforad")}{" "}
          <Link
            target="_blank"
            to={`/profile/${slugProfile}`}
            className={"green"}
          >
            {name}
          </Link>{" "}
          {t("paiduntil")} { moment(elite_to).format("YYYY-MM-DD")} {t("ifdeactivate")}.
        </div>

        <div className="popup-form__buttons">
          <Button
            size={"l"}
            clazz={"button--secondary justify-center"}
            onClick={onSubmit}
          >
            {t("deactive")}
          </Button>

          <Button
            size={"l-forever"}
            clazz={"button--green justify-center"}
            onClick={() => setOpen(false)}
          >
            {t("keep")} ELITE
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default RemoveEliteAdPopup;
